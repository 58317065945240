import { useEffect, useState, useCallback } from "react";
import axios from "axios";

import {
  Grid,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { ITeamResponse } from "../../types/team";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { track } from "@amplitude/analytics-browser";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import Helper from "../../components/Helper";
import { TeamsHelper } from "../../constants/helpers";
import Plus from "../../components/Icons/Plus";
import { openModal } from "../../features/modals";
import MODALS from "../../constants/modals";
import NoTeams from "./DistributeCourses/NoTeams";
import moment from "moment";

const Teams = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<ITeamResponse>();

  const loadData = useCallback(() => {
    setLoading(true);
    axios
      .get<ITeamResponse>("/Teams/GetAllTeams")
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData();
    track("OpenedTeamsView");
  }, [loadData]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Team",
      sortable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "createdOn",
      headerName: "Created on",
      sortable: false,
      valueFormatter: (params) => {
        return moment
          .utc(params.value as string)
          .local()
          .format("L LT");
      },
      minWidth: 180,
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Teams
            </Typography>
            <Helper title={<TeamsHelper />} />
          </Stack>
        </Grid>
        <Grid item>
          <Button
            className="create-team-button"
            variant="contained"
            startIcon={<Plus color="#fff" />}
            onClick={() => {
              dispatch(
                openModal({
                  type: MODALS.TeamType,
                  callback: () => {
                    loadData();
                  },
                })
              );
            }}
          >
            Add team
          </Button>
        </Grid>
      </Grid>

      {isLoading ? (
        <Stack flex={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : !data || data.teams.length === 0 ? (
        <NoTeams
          callback={() => {
            loadData();
          }}
        />
      ) : (
        <DataGrid
          getRowId={(row) => row.id}
          rows={data ? data.teams : []}
          rowCount={data ? data.numberOfEntries : 0}
          columns={columns}
          loading={isLoading}
          slotProps={{}}
          autoHeight
          disableRowSelectionOnClick
          disableColumnMenu
          pageSizeOptions={[100]}
        />
      )}
    </Stack>
  );
};

export default Teams;
