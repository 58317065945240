import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { openModal } from "../../features/modals";
import MODALS from "../../constants/modals";
import { useAppDispatch } from "../../hooks/redux";

interface IAction {
  label: string;
  callback: () => void;
  isLoading?: boolean;
  approve?: {
    title: string;
  };
  disabled?: boolean;
  className?: string;
  startIcon?: React.ReactNode;
  href?: string;
}

interface IModalActionsProps {
  neutral?: IAction;
  positive?: IAction | IAction[];
  negative?: IAction;
}

const Actions: React.FC<IModalActionsProps> = ({
  neutral,
  positive,
  negative,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Stack
      sx={{
        paddingTop: 2,
      }}
      direction="row"
      justifyContent="space-between"
    >
      <Stack direction="row">
        {negative && (
          <LoadingButton
            className={negative.className}
            disabled={negative.disabled}
            onClick={() => {
              if (negative.approve) {
                dispatch(
                  openModal({
                    type: MODALS.Approve,
                    title: negative.approve.title,
                    onApprove: () => {
                      negative.callback();
                    },
                  })
                );
              } else {
                negative.callback();
              }
            }}
            variant="outlined"
            loading={negative.isLoading}
          >
            {negative.label}
          </LoadingButton>
        )}
      </Stack>
      <Stack alignItems="flex-end" spacing={2} direction="row">
        {neutral && (
          <LoadingButton
            className={neutral.className}
            disabled={neutral.disabled}
            onClick={() => {
              if (neutral.approve) {
                dispatch(
                  openModal({
                    type: MODALS.Approve,
                    title: neutral.approve.title,
                    onApprove: () => {
                      neutral.callback();
                    },
                  })
                );
              } else {
                neutral.callback();
              }
            }}
            variant="outlined"
            loading={neutral.isLoading}
          >
            {neutral.label}
          </LoadingButton>
        )}
        {positive &&
          (positive instanceof Array ? (
            positive.map((action, index) => (
              <ActionButton key={`positiveAction${index}`} {...action} />
            ))
          ) : (
            <ActionButton {...positive} />
          ))}
      </Stack>
    </Stack>
  );
};

const ActionButton: React.FC<IAction> = ({
  className,
  disabled,
  approve,
  callback,
  href,
  isLoading,
  startIcon,
  label,
}) => {
  const dispatch = useAppDispatch();

  return (
    <LoadingButton
      className={className}
      disabled={disabled}
      onClick={() => {
        if (approve) {
          dispatch(
            openModal({
              type: MODALS.Approve,
              title: approve.title,
              onApprove: () => {
                callback();
              },
            })
          );
        } else {
          callback();
        }
      }}
      type={href ? undefined : "submit"}
      variant="contained"
      loading={isLoading}
      startIcon={startIcon}
      href={href}
    >
      {label}
    </LoadingButton>
  );
};

export default Actions;
