import { TRoute, RouteType } from "../types/route";

import ROLES from "../constants/roles";

import Teams from "../views/hub/Teams";
// import TeamsEdit from "../views/hub/TeamsEdit";
import Content from "../views/hub/Content";
import ContentEdit from "../views/Content/Edit";
import Access from "../views/hub/Access";
import Leaderboard from "../views/hub/Leaderboard";
// import ContentAnalytics from "../views/hub/analytics/Content";

// import QuizzesAnalytics from "../views/hub/analytics/Quizzes";
import TrendsAnalytics from "../views/hub/analytics/Trends";
// import Challenges from "../views/hub/Challenges";
// import ChallengesAnalytics from "../views/hub/analytics/Challenges";
import Licenses from "../views/hub/Licenses";
import Announcements from "../views/hub/Announcements";
// import Tests from "../views/hub/Tests";
// import TestsAnalytics from "../views/hub/analytics/Tests";
import DistributeCourses from "../views/hub/DistributeCourses";

import TeamsAnalytics from "../views/hub/analytics/Teams/Teams";
import TeamAnalytics from "../views/hub/analytics/Teams/Team";
import TeamCourseAnalytics from "../views/hub/analytics/Teams/Course";

import EmployeesAnalytics from "../views/hub/analytics/Employees/Employees";
import EmployeeAnalytics from "../views/hub/analytics/Employees/Employee";
import EmployeeCourseAnalytics from "../views/hub/analytics/Employees/Course";

import BookOpen from "../components/Icons/BookOpen";
import Users from "../components/Icons/Users";
// import Trophy from "../components/Icons/Trophy";
import ChartBar from "../components/Icons/ChartBar";
import BellRing from "../components/Icons/BellRing";
import Analytics from "../components/Icons/Analytics";
// import CommentQuestion from "../components/Icons/CommentQuestion";
import ChartLine from "../components/Icons/ChartLine";
import CreditCardFront from "../components/Icons/CreditCardFront";
import Key from "../components/Icons/Key";
import QuestionCircle from "../components/Icons/QuestionCircle";
// import NoteClipboard from "../components/Icons/NoteClipboard";
import Plane from "../components/Icons/Plane";
import User from "../components/Icons/User";

// INFO: path /docs is reserved route for documentation and handled as static content

export const routes: TRoute[] = [
  {
    type: RouteType.Menu,
    path: "/courses",
    element: Content,
    name: "Courses",
    icon: <BookOpen />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Menu,
    path: "/teams",
    element: Teams,
    name: "Teams",
    icon: <Users />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Menu,
    path: "/distribute-courses",
    element: DistributeCourses,
    name: "Distribute Courses",
    icon: (
      <Plane
        style={{
          transform: "rotate(90deg)",
        }}
      />
    ),
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Basic,
    path: "/courses/:questionCategoryTranslationsId",
    element: ContentEdit,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Menu,
    path: "/leaderboard",
    element: Leaderboard,
    name: "Leaderboard",
    icon: <ChartBar />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Menu,
    path: "/announcements",
    element: Announcements,
    name: "Announcements",
    icon: <BellRing />,
    allowedRoles: [ROLES.WorkspaceAdmin, ROLES.IntervyAdmin],
  },
  {
    type: RouteType.Group,
    name: "Analytics",
    icon: <Analytics />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
    routes: [
      // {
      //   type: RouteType.Menu,
      //   path: "/analytics/courses",
      //   element: ContentAnalytics,
      //   name: "Courses",
      //   icon: <BookOpen />,
      //   allowedRoles: [
      //     ROLES.WorkspaceAdmin,
      //     ROLES.IntervyAdmin,
      //     ROLES.SubscriptionAdmin,
      //   ],
      // },
      {
        type: RouteType.Menu,
        path: "/analytics/trends",
        element: TrendsAnalytics,
        name: "Trends",
        icon: <ChartLine />,
        allowedRoles: [
          ROLES.WorkspaceAdmin,
          ROLES.IntervyAdmin,
          ROLES.SubscriptionAdmin,
        ],
      },
      {
        type: RouteType.Menu,
        path: "/analytics/teams",
        element: TeamsAnalytics,
        name: "Teams",
        icon: <Users />,
        allowedRoles: [
          ROLES.WorkspaceAdmin,
          ROLES.IntervyAdmin,
          ROLES.SubscriptionAdmin,
        ],
      },
      {
        type: RouteType.Menu,
        path: "/analytics/employees",
        element: EmployeesAnalytics,
        name: "Employees",
        icon: <User />,
        allowedRoles: [
          ROLES.WorkspaceAdmin,
          ROLES.IntervyAdmin,
          ROLES.SubscriptionAdmin,
        ],
      },
    ],
  },
  {
    type: RouteType.Basic,
    path: "/analytics/teams/:teamId",
    element: TeamAnalytics,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Basic,
    path: "/analytics/teams/:teamId/course/:courseTrainingType/:courseDistributionId",
    element: TeamCourseAnalytics,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Basic,
    path: "/analytics/employees/:employeeId",
    element: EmployeeAnalytics,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Basic,
    path: "/analytics/employees/:employeeId/course/:courseTrainingType/:courseDistributionId",
    element: EmployeeCourseAnalytics,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Menu,
    path: "/licenses",
    element: Licenses,
    name: "License Management",
    icon: <CreditCardFront />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Menu,
    path: "/access",
    element: Access,
    name: "Access",
    icon: <Key />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },

  {
    name: "Help Center",
    type: RouteType.Link,
    link: "/docs",
    icon: <QuestionCircle />,
  },
];
