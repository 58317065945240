import { IApproveModalProps, ITrialEndedProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import Actions from "../components/Modal/Actions";
import LockMulticolor from "../components/Icons/LockMulticolor";
import KeyMulticolor from "../components/Icons/KeyMulticolor";

import { track } from "@amplitude/analytics-browser";

const TrialEndedModal: React.FunctionComponent<ITrialEndedProps> = ({
  id,
  title,
  message,
  action,
}) => {
  const dispatch = useAppDispatch();

  const handleApprove = () => {
    track("HubClickedUpgrade");
    handleClose();
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogContent>
        <Stack direction="row" alignItems="center">
          <LockMulticolor width={48} height={48} />
          <Typography variant="h4" fontWeight={700}>
            {title}
          </Typography>
        </Stack>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {message}
        </Typography>
        <Actions
          neutral={{
            label: "Cancel",
            callback: handleClose,
          }}
          positive={{
            startIcon: (
              <KeyMulticolor
                style={{
                  transform: "rotate(45deg) scale(1, -1)",
                }}
              />
            ),
            label: action,
            callback: handleApprove,
            href: "https://appsource.microsoft.com/en-us/product/web-apps/dots-40103853272.dots_intervy?tab=PlansAndPrice",
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TrialEndedModal;
