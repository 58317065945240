import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,

  rotate = 0,
  strokeWidth = 4,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8333 38.25C18.8208 38.25 18 39.0708 18 40.0833L18 66.4167C18 67.4292 18.8208 68.25 19.8333 68.25H60.1667C61.1792 68.25 62 67.4292 62 66.4167L62 40.0833C62 39.0708 61.1792 38.25 60.1667 38.25L19.8333 38.25ZM43 56.25C41.3431 57.9069 38.6569 57.9069 37 56.25C35.3431 54.5931 35.3431 51.9069 37 50.25C38.6569 48.5931 41.3431 48.5931 43 50.25C44.6569 51.9069 44.6569 54.5931 43 56.25Z"
        fill="#F2C94C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 14.25C31.4396 14.25 24.5 21.1896 24.5 29.75V38.25H28.5V29.75C28.5 23.3987 33.6487 18.25 40 18.25C46.3513 18.25 51.5 23.3987 51.5 29.75V38.25H55.5V29.75C55.5 21.1896 48.5604 14.25 40 14.25Z"
        fill="#828282"
      />
    </svg>
  );
}
