// utils
import { createSlice } from "@reduxjs/toolkit";

export interface IInteractions {
  courseDistributionPaused: boolean;
}

const initialState: IInteractions = {
  courseDistributionPaused: false,
};

const interactionsSlice = createSlice({
  name: "interactions",
  initialState,
  reducers: {
    setCourseDistributionPaused: (state, { payload }: { payload: any }) => {
      state.courseDistributionPaused = payload;
    },
  },
});

export const { setCourseDistributionPaused } = interactionsSlice.actions;

export default interactionsSlice.reducer;
