import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

import ROLES from "./constants/roles";
import { routes } from "./config/routes";
import { routes as clientRoutes } from "./config/clientRoutes";
import { useAppSelector } from "./hooks/redux";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";

import Layout from "./Layout";
import { Link, Skeleton, Stack, Typography } from "@mui/material";

import denied from "./assets/img/denied.svg";
import { RouteType, TRoute } from "./types/route";

import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";

import configParams from "./config/params";

import Hotjar from "@hotjar/browser";
import axios from "axios";
import { BasicUser } from "./types/user";

type HubProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: HubProps) {
  const navigate = useNavigate();

  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  const me = useAppSelector((state) => state.auth.me);

  useEffect(() => {
    let env = "";

    if (process.env.REACT_APP_ENVIRONMENT) {
      env = ` - ${process.env.REACT_APP_ENVIRONMENT}`;
    }

    document.title = configParams.webDomains.includes(window.location.host)
      ? `Ervy Web${env}`
      : `Ervy Hub${env}`;

    if (!configParams.webDomains.includes(window.location.host)) {
      Hotjar.init(5214733, 6);
    }
  }, []);

  return (
    <MsalProvider instance={pca}>
      <Layout>
        {configParams.webDomains.includes(window.location.host) && me ? (
          <Routes>
            {clientRoutes.map((route) => {
              return renderRoute(route, me.roleId);
            })}
            <Route path="*" element={<Navigate to="/courses" replace />} />
          </Routes>
        ) : me && me.roleId !== ROLES.User ? (
          <Routes>
            {routes.map((route) => {
              return renderRoute(route, me.roleId);
            })}
            <Route path="*" element={<Navigate to="/courses" replace />} />
          </Routes>
        ) : (
          <NoPermissions />
        )}
        <SnackbarProvider />
      </Layout>
    </MsalProvider>
  );
}

function renderRoute(route: TRoute, roleId: ROLES): React.ReactNode {
  if (route.type === RouteType.Link) {
    return null;
  }

  if (route.allowedRoles) {
    if (!route.allowedRoles.includes(roleId)) {
      return null;
    }
  }

  if (route.type === RouteType.Group) {
    return route.routes.map((e) => renderRoute(e, roleId));
  }

  return (
    <Route key={route.path} path={route.path} element={<route.element />} />
  );
}

function NoPermissions() {
  const [loading, setLoading] = useState(true);
  const [admins, setAdmins] = useState<BasicUser[]>([]);

  useEffect(() => {
    axios
      .get<{
        admins: BasicUser[];
      }>("/Auth/GetAdmins")
      .then((res) => {
        setAdmins(res.data.admins);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Stack
      flex={1}
      alignItems="center"
      justifyContent="center"
      style={{
        position: "relative",
      }}
    >
      <Stack
        spacing={2}
        style={{
          zIndex: 2,
        }}
      >
        {/* <Typography variant="h3" component="h1">
        Insufficient permissions
        </Typography> */}
        <Typography>
          Hey there! 👋 Ready to enter the Ervy admin portal? You just need a
          quick OK from your workspace admin. They hold the keys to get you
          started! 🔓
        </Typography>
        <Typography>Your admins:</Typography>
        {loading ? (
          <Stack>
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={200} />
          </Stack>
        ) : (
          <ul>
            {admins.map((admin) => (
              <li key={admin.email}>
                <Link href={`mailto:${admin.email}`}>{admin.displayName}</Link>
              </li>
            ))}
          </ul>
        )}
        <Typography>Give a quick ping, and let’s get you in! 😉</Typography>
      </Stack>
      <img
        src={denied}
        style={{
          width: "100%",
          maxWidth: 520,
          opacity: 0.3,
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
        }}
      />
    </Stack>
  );
}

export default App;
