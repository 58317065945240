import { createRootEditorSubscription$, realmPlugin } from "@mdxeditor/editor";
import {
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  TextNode,
} from "lexical";

const IS_UNDERLINE = 0b1000 as const;

export const customDisableUnderlinePlugin = realmPlugin({
  init(realm) {
    realm.pub(createRootEditorSubscription$, (lexicalEditor) => {
      return lexicalEditor.registerCommand(
        FORMAT_TEXT_COMMAND,
        (payload) => {
          if (payload === "underline") {
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      );
    });

    realm.pub(createRootEditorSubscription$, (lexicalEditor) => {
      return lexicalEditor.registerNodeTransform(TextNode, (textNode) => {
        if ((textNode.getFormat() & IS_UNDERLINE) !== 0) {
          textNode.setFormat(textNode.getFormat() & ~IS_UNDERLINE);
        }
      });
    });
  },
});
