import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Switch,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import FileAdd from "../../../components/Icons/FileAdd";
import Plus from "../../../components/Icons/Plus";
import Close from "../../../components/Icons/Close";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IContentData, IContentEdit } from "../../../types/content";
import axios from "axios";
import Difficulty from "../../../constants/difficulties";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { openModal } from "../../../features/modals";
import MODALS from "../../../constants/modals";
import { toast } from "react-toastify";
import { track } from "@amplitude/analytics-browser";
import Question from "./Question";
import CustomPaper from "../../../components/CustomPaper";

import DragHandleIcon from "@mui/icons-material/DragHandle";
import QUESTION_STATUSES from "../../../constants/questionStatuses";
import { LoadingButton } from "@mui/lab";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import _ from "lodash";
import { setRun } from "../../../features/tour";
import LESSON_TYPE from "../../../constants/lessonType";

import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface ILessonsProps {
  data: IContentEdit;
  reloadCallback: () => void;
}

const Lessons: React.FC<ILessonsProps> = ({ data, reloadCallback }) => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const me = useAppSelector((state) => state.auth.me);
  const tour = useAppSelector((state) => state.tour);

  const [selectedContent, setSelectedContent] = useState<IContentData>();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [isSaving, setIsSaving] = useState(false);

  const [isEnableLoading, setIsEnableLoading] = useState(false);
  const [isDisableLoading, setIsDisableLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [content, setContent] = useState<IContentData[]>(data.editContentData);

  const beforeQuestionSelect = useCallback(
    (callback: () => void) => {
      if (isFormDirty) {
        if (
          window.confirm(
            "You have unsaved changes. Are you sure you want to discard them?"
          )
        ) {
          callback();
        }
      } else {
        callback();
      }
    },
    [isFormDirty]
  );

  const addQuestion = useCallback(() => {
    setSelectedContent({
      questionId: 0,
      lessonType: LESSON_TYPE.Quiz,
      text: "",
      status: 0,
      languageId: 1,
      difficulty: Difficulty.Easy,
      answers: [],
      orderIndex: content.length + 1,
    });
  }, [content]);

  const onDeleteQuestion = (questions: number[]) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        openModal({
          type: MODALS.Approve,
          title:
            questions.length === 1
              ? `Are you sure you want to delete lesson?`
              : "Are you sure you want to delete selected lessons?",
          onApprove: () => {
            setIsDeleteLoading(true);
            axios
              .post("/Content/DeleteQuestions", { questions })
              .then((res) => {
                toast(
                  questions.length === 1
                    ? "Lesson deleted successfully"
                    : "Lessons deleted successfully",
                  {
                    type: "success",
                  }
                );
                setSelectedContent(undefined);
                reloadCallback();
                track("DeletedContentQuestion");
                resolve();
              })
              .catch(() => {
                reject();
              })
              .finally(() => {
                setIsDeleteLoading(false);
              });
          },
        })
      );
    });
  };

  const handleQuestionCallback = useCallback(
    (question: IContentData, isNew?: boolean) => {
      if (isNew) {
        setSelectedContent(question);
      } else {
        if (selectedContent?.questionId === question.questionId) {
          setSelectedContent(question);
        }
      }
      reloadCallback();
    },
    [selectedContent]
  );

  const handleBulkStatusChange = useCallback(
    (status: QUESTION_STATUSES) => {
      axios
        .post<{
          status: string;
          lessonType: LESSON_TYPE;
          questionIds: number[];
        }>("/Content/ChangeQuestionStatus", {
          questionStatuses: Object.keys(rowSelection).map((key) => {
            const question = data.editContentData[parseInt(key)];

            return {
              questionId: question.questionId,
              lessonType: question.lessonType,
              status,
            };
          }),
        })
        .then((res) => {
          if (status === QUESTION_STATUSES.Visible) {
            if (res.data.status === "Failed") {
              toast(
                "Attention! Some lessons couldn't be enabled due to missing information. Please review and complete all required lesson details for them to be included in the course.",
                {
                  type: "warning",
                }
              );
            } else {
              toast(
                "Success! Selected lessons within the course have been enabled. This course can now be distributed within the 'Distribute Courses' section or here by clicking the 'Distribute this course' button.",
                {
                  type: "success",
                }
              );
            }
          } else {
            toast(
              "Success! Selected lessons within the course have been disabled. This course cannot be distributed within the 'Distribute Courses' section until it contains at least one enabled lesson.",
              {
                type: "success",
              }
            );
          }
          reloadCallback();
        })
        .catch((err) => {})
        .finally(() => {
          setIsEnableLoading(false);
          setIsDisableLoading(false);
        });
    },
    [data, rowSelection]
  );

  useEffect(() => {
    if (data.editContentData.length > 0) {
      setSelectedContent((currentState) =>
        currentState ? currentState : data.editContentData[0]
      );
    } else {
      addQuestion();
    }

    setContent(data.editContentData);
  }, [data]);

  const columns = useMemo<MRT_ColumnDef<IContentData>[]>(
    () => [
      {
        accessorKey: "positiveFeedback",
        header: "",
        Header(props) {
          const total = data.editContentData.reduce(
            (acc, value) => acc + (value.positiveFeedback || 0),
            0
          );
          return <Chip label={`👍 ${total}`} />;
        },
        Cell(props) {
          return <Chip label={`👍 ${props.row.original.positiveFeedback}`} />;
        },
        size: 110,
        maxSize: 110,
        grow: false,
      },
      {
        accessorKey: "negativeFeedback",
        header: "",
        Header(props) {
          const total = data.editContentData.reduce(
            (acc, value) => acc + (value.negativeFeedback || 0),
            0
          );
          return <Chip label={`👎 ${total}`} />;
        },
        Cell(props) {
          return <Chip label={`👎 ${props.row.original.negativeFeedback}`} />;
        },
        size: 110,
        maxSize: 110,
        grow: false,

        hidden: data.questionCategoryEnumId === 1 || !data.canEdit,
      },
      {
        accessorKey: "text",
        header: "",
        enableSorting: false,
        Cell(props) {
          return (
            <TextCell
              row={props.row.original}
              isSelect={
                props.row.original.questionId === selectedContent?.questionId
              }
              disabled={!data?.canEdit}
              callback={(data) => {
                handleQuestionCallback(data);
              }}
            />
          );
        },
        minSize: 300,
      },
    ],
    [data.editContentData, selectedContent]
  );

  const table = useMaterialReactTable({
    columns,
    data: content,
    enableRowSelection: true,
    enableColumnOrdering: true,
    enableGlobalFilter: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableColumnDragging: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableRowOrdering: true,
    state: {
      rowSelection,
      density: "compact",
      columnVisibility: {
        positiveFeedback: data.questionCategoryEnumId !== 1 && data.canEdit,
        negativeFeedback: data.questionCategoryEnumId !== 1 && data.canEdit,
      },
      isSaving: isSaving,
    },
    displayColumnDefOptions: {
      "mrt-row-drag": {
        header: "Order",
      },
    },
    onRowSelectionChange: setRowSelection,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          setIsSaving(true);
          const tmp = content.slice();
          content.splice(
            (hoveredRow as MRT_Row<IContentData>).index,
            0,
            content.splice(draggingRow.index, 1)[0]
          );
          setContent([...content]);
          axios
            .post("/Content/UpdateQuestionOrder", {
              courseId: data.questionCategoryId,
              questionId: draggingRow.original.questionId,
              newOrderIndex: (hoveredRow as MRT_Row<IContentData>).index + 1,
            })
            .then(() => {
              reloadCallback();
            })
            .catch(() => {
              setContent(tmp);
            })
            .finally(() => {
              setIsSaving(false);
            });
        }
      },
    }),
    layoutMode: "grid",
    muiTablePaperProps: {
      elevation: 0,
      style: {
        border: "1px solid black",
        borderRadius: 16,
      },
    },
    muiTableContainerProps: {
      style: {
        maxHeight: "100%",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "1px solid black",
        "& .Mui-TableHeadCell-Content": {
          height: 50,
        },
      },
    },
    muiSelectCheckboxProps: {
      size: "medium",
    },
    muiSelectAllCheckboxProps: {
      size: "medium",
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        beforeQuestionSelect(() => setSelectedContent(row.original));
      },
    }),
  });

  useEffect(() => {
    if (tour.index === 4) {
      dispatch(setRun(true));
    }
  }, []);

  return (
    <PanelGroup
      autoSaveId="example"
      direction="horizontal"
      style={{
        minHeight: 400,
        flex: 1,
      }}
    >
      {(!mdDown || !selectedContent) && (
        <Panel
          defaultSize={35}
          style={{
            display: "flex",
            minWidth: 470,
          }}
        >
          <Stack
            spacing={2}
            style={{
              maxHeight: "100%",
              flex: 1,
              maxWidth: "100%",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3">Lessons</Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Tooltip title="Generate lessons from file">
                  <Button
                    className="generate-from-file-button"
                    disabled={!data?.canEdit}
                    startIcon={<FileAdd width={25} height={25} color="#000" />}
                    variant="outlined"
                    onClick={() => {
                      if (me?.freeTrialEnded) {
                        dispatch(
                          openModal({
                            type: MODALS.TrialEnded,
                            title: "AI course builder locked",
                            message:
                              "Your free trial has expired, and the AI course builder is locked. Upgrade to a licensed plan to unlock this powerful tool and create courses effortlessly!",
                            action: "Unlock now",
                          })
                        );

                        return;
                      }

                      if (!data?.questionCategoryId) {
                        return;
                      }
                      dispatch(
                        openModal({
                          type: MODALS.UploadContent,
                          questionCategoryId: data.questionCategoryId,
                          callback: () => {
                            reloadCallback();
                          },
                        })
                      );
                    }}
                  >
                    AI course builder
                  </Button>
                </Tooltip>
                <Tooltip title="Add new lesson">
                  <Button
                    disabled={!data.canEdit}
                    variant="outlined"
                    startIcon={<Plus width={25} height={25} color="#000" />}
                    onClick={() => beforeQuestionSelect(addQuestion)}
                  >
                    Add
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>

            {!_.isEmpty(rowSelection) && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                flexWrap="wrap"
              >
                <Typography>{_.size(rowSelection)} entries selected</Typography>
                <LoadingButton
                  loading={isEnableLoading}
                  disabled={isDisableLoading}
                  variant="text"
                  onClick={() => {
                    setIsEnableLoading(true);
                    handleBulkStatusChange(QUESTION_STATUSES.Visible);
                  }}
                >
                  Enable selected
                </LoadingButton>

                <LoadingButton
                  loading={isDisableLoading}
                  disabled={isEnableLoading}
                  variant="text"
                  onClick={() => {
                    setIsDisableLoading(true);
                    handleBulkStatusChange(QUESTION_STATUSES.Invisible);
                  }}
                >
                  Disable selected
                </LoadingButton>

                <LoadingButton
                  loading={isDeleteLoading}
                  disabled={isEnableLoading || isDisableLoading}
                  variant="text"
                  onClick={() => {
                    onDeleteQuestion(
                      Object.keys(rowSelection).map((key) => {
                        const question = data.editContentData[parseInt(key)];

                        return question.questionId;
                      })
                    ).then(() => {
                      setRowSelection({});
                    });
                  }}
                >
                  Delete selected
                </LoadingButton>
              </Stack>
            )}

            <MaterialReactTable table={table} />
          </Stack>
        </Panel>
      )}
      {selectedContent && !mdDown && (
        <PanelResizeHandle
          style={{
            position: "relative",
            width: 8,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: 4,
            }}
            sx={(theme: Theme) => ({
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
            })}
          >
            <DragHandleIcon
              style={{
                transform: "rotate(90deg)",
              }}
            />
          </Stack>
        </PanelResizeHandle>
      )}
      {selectedContent && (
        <Panel
          className="lesson-tab"
          style={{
            display: "flex",
            minWidth: 345,
          }}
        >
          <Stack
            spacing={2}
            style={{
              maxHeight: "100%",
              flex: 1,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3">Edit lesson</Typography>
              <IconButton
                onClick={() =>
                  beforeQuestionSelect(() => setSelectedContent(undefined))
                }
                size="small"
              >
                <Close color="#000" width={48} height={48} />
              </IconButton>
            </Stack>

            <CustomPaper
              sx={{ flex: 1 }}
              style={{
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  p: 1,
                }}
              >
                <Question
                  questionCategoryId={data.questionCategoryId}
                  questionCategoryEnumId={data.questionCategoryEnumId}
                  question={selectedContent}
                  disabled={!data?.canEdit}
                  onDelete={(questionId) => {
                    onDeleteQuestion([questionId]);
                  }}
                  successCallback={handleQuestionCallback}
                  onDirtyChange={setIsFormDirty}
                  topics={data.allTopics}
                />
              </Box>
            </CustomPaper>
          </Stack>
        </Panel>
      )}
    </PanelGroup>
  );
};

interface ITextCellProps {
  row: IContentData;
  isSelect?: boolean;
  disabled?: boolean;
  callback: (data: IContentData) => void;
}

const TextCell: React.FC<ITextCellProps> = ({
  row,
  isSelect,
  callback,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      flex={1}
      style={{
        maxWidth: "100%",
      }}
    >
      <Typography
        fontWeight={isSelect ? 700 : 400}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <ReactMarkdown
          disallowedElements={["p"]}
          unwrapDisallowed
          // @ts-ignore
          rehypePlugins={[rehypeRaw]}
        >
          {row.text}
        </ReactMarkdown>
      </Typography>
      <Stack
        direction="row"
        style={{
          width: 58,
        }}
        alignItems="center"
        justifyContent="center"
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Switch
            disabled={disabled}
            checked={row.status === QUESTION_STATUSES.Visible}
            onClick={(e) => {
              e.stopPropagation();
              setIsLoading(true);
              axios
                .post("/Content/ChangeQuestionStatus", {
                  questionStatuses: [
                    {
                      questionId: row.questionId,
                      lessonType: row.lessonType,
                      status:
                        row.status === QUESTION_STATUSES.Visible
                          ? QUESTION_STATUSES.Invisible
                          : QUESTION_STATUSES.Visible,
                    },
                  ],
                })
                .then((res) => {
                  callback({
                    ...row,
                    status:
                      row.status === QUESTION_STATUSES.Visible
                        ? QUESTION_STATUSES.Invisible
                        : QUESTION_STATUSES.Visible,
                  });
                })
                .catch(() => {})
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default Lessons;
