import {
  IApproveModalProps,
  IEnableAndDistributeCourseProps,
  ITrialEndedProps,
} from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import Actions from "../components/Modal/Actions";
import LockMulticolor from "../components/Icons/LockMulticolor";
import KeyMulticolor from "../components/Icons/KeyMulticolor";

import { track } from "@amplitude/analytics-browser";
import BellMulticolor from "../components/Icons/BellMulticolor";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

const EnableAndDistributeCourseModal: React.FunctionComponent<
  IEnableAndDistributeCourseProps
> = ({ id, courseId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isEnableAllLoading, setIsEnableAllLoading] = useState(false);

  const handleEnable = () => {
    setIsEnableAllLoading(true);

    axios
      .post("/Content/EnableAllCourseLessons", {
        courseId,
      })
      .then((response) => {
        navigate(`/distribute-courses`, {
          state: {
            course: parseInt(courseId),
          },
        });
        handleClose();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsEnableAllLoading(false);
      });
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" open={true} onClose={handleClose}>
      <DialogContent>
        <Stack direction="row" alignItems="center">
          <BellMulticolor width={48} height={48} />
          <Typography variant="h4" fontWeight={700}>
            This course has no enabled lessons
          </Typography>
        </Stack>
        <Typography variant="body1" sx={{ mt: 2 }}>
          This course has no lessons currently enabled, so it cannot be
          distributed.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          To fix this, you can:
          <ol>
            <li>
              Automatically enable all lessons now and distribute the course
              immediately.
            </li>
            <li>
              Edit the course to review and enable specific lessons manually.
            </li>
          </ol>
        </Typography>
        <Actions
          neutral={{
            label: "Cancel",
            callback: handleClose,
          }}
          positive={[
            {
              label: "Enable all lessons & distribute",
              callback: handleEnable,
              isLoading: isEnableAllLoading,
            },
            {
              label: "Edit course",
              callback: () => {
                navigate(`/courses/${courseId}`);
                handleClose();
              },
            },
          ]}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EnableAndDistributeCourseModal;
