import * as yup from "yup";

const sendAnnouncementSchema = yup.object({
  channelIds: yup
    .array(yup.number().required())
    .required()
    .min(1, "Name is a required field")
    .label("Name"),
    announcementAdaptiveCard: yup.string().required().min(3).label("Text"),
});

export type AnnouncementBase = yup.InferType<typeof sendAnnouncementSchema>;

export default sendAnnouncementSchema;
