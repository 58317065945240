import { useEffect, useCallback, useState } from "react";

import MODALS from "../../../constants/modals";

import axios from "axios";
import { toast } from "react-toastify";

import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import {
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Stack,
  Tab,
  Button,
  Tooltip,
} from "@mui/material";

import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { openModal } from "../../../features/modals";
import { IContentEdit } from "../../../types/content";

import { track } from "@amplitude/analytics-browser";
import ContentDetails from "./Details";
import ContentPrompts from "./Prompts";

import Trash from "../../../components/Icons/Trash";

import Chevron from "../../../components/Icons/Chevron";
import Lessons from "./Lessons";
import Plane from "../../../components/Icons/Plane";
import QUESTION_STATUSES from "../../../constants/questionStatuses";

const ContentEdit = () => {
  const { questionCategoryTranslationsId } = useParams<{
    questionCategoryTranslationsId: string;
  }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const me = useAppSelector((state) => state.auth.me);

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<IContentEdit>();

  const [isDeleting, setIsDeleting] = useState(false);

  const loadData = useCallback(() => {
    setLoading(true);
    axios
      .get<IContentEdit>("/Content/GetEditContent", {
        params: {
          questionCategoryId: questionCategoryTranslationsId,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [questionCategoryTranslationsId]);

  useEffect(() => {
    loadData();
    track("OpenedContentEdit");
  }, [loadData]);

  const onContentDelete = useCallback(() => {
    dispatch(
      openModal({
        type: MODALS.Approve,
        title: (
          <Typography variant="h6" fontWeight={700}>
            Are you sure you want to delete course "{data?.name}"?
          </Typography>
        ),
        message:
          "If the course has been distributed, all future lesson distributions will be stopped immediately. Completed lessons will still be available in the analytics, but the course itself will no longer be accessible.",
        onApprove: () => {
          setIsDeleting(true);
          axios
            .post("/Content/DeleteContent", {
              questionCategoryId: data?.questionCategoryId,
            })
            .then((res) => {
              track("DeletedContent");

              toast(`Course "${data?.name}" deleted successfully`, {
                type: "success",
              });
              navigate(`/courses`);
            });
        },
      })
    );
  }, [data?.name, data?.questionCategoryId]);

  const [tab, setTab] = useState("2");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Stack
      spacing={2}
      flex={1}
      style={{
        maxHeight: "calc(100vh - 210px)",
      }}
    >
      <Grid container>
        <Grid item flex={1}>
          <Stack>
            <Typography component="h1" variant="h2">
              Courses
            </Typography>
            <Breadcrumbs
              separator={
                <Chevron
                  width={20}
                  height={20}
                  style={{
                    transform: "rotate(90deg)",
                  }}
                />
              }
            >
              <Link
                component={RouterLink}
                to={"/courses"}
                underline="none"
                color="inherit"
              >
                {data?.name}
              </Link>
              <Typography variant="inherit">
                {data?.canEdit ? "Edit" : "View"}
              </Typography>
            </Breadcrumbs>
          </Stack>
        </Grid>
        <Grid
          item
          sx={{
            marginRight: 2,
          }}
        >
          <LoadingButton
            disabled={!data?.canEdit}
            loading={isDeleting}
            variant="outlined"
            startIcon={<Trash width={25} height={25} color="#000" />}
            onClick={onContentDelete}
          >
            Delete this course
          </LoadingButton>
        </Grid>
        <Grid item>
          {data &&
          data.editContentData.filter(
            (lesson) => lesson.status === QUESTION_STATUSES.Visible
          ).length === 0 ? (
            <Tooltip
              title={
                data.editContentData.length > 0
                  ? "To distribute this course, please enable at least one lesson."
                  : "To distribute this course, please add and enable at least one lesson."
              }
            >
              <span>
                <Button
                  disabled
                  variant="contained"
                  startIcon={
                    <Plane
                      width={25}
                      height={25}
                      color="rgba(0, 0, 0, 0.26)"
                      style={{
                        transform: "rotate(90deg)",
                      }}
                    />
                  }
                >
                  Distribute this course
                </Button>
              </span>
            </Tooltip>
          ) : me?.freeTrialEnded && data?.questionCategoryEnumId !== 1 ? (
            <Button
              variant="contained"
              onClick={() => {
                dispatch(
                  openModal({
                    type: MODALS.TrialEnded,
                    title: "Course distribution is limited",
                    message:
                      "Your free trial has ended, and course distributions have been limited to the social engagement course, 'Do you know your colleagues well?'. To resume distributing other courses, please upgrade to a licensed plan",
                    action: "Upgrade now",
                  })
                );
              }}
              startIcon={
                <Plane
                  width={25}
                  height={25}
                  color="#fff"
                  style={{
                    transform: "rotate(90deg)",
                  }}
                />
              }
            >
              Distribute this course
            </Button>
          ) : (
            <Link
              component={RouterLink}
              to="/distribute-courses"
              state={{
                course: data?.questionCategoryId,
              }}
            >
              <Button
                variant="contained"
                startIcon={
                  <Plane
                    width={25}
                    height={25}
                    color="#fff"
                    style={{
                      transform: "rotate(90deg)",
                    }}
                  />
                }
              >
                Distribute this course
              </Button>
            </Link>
          )}
        </Grid>
      </Grid>
      <TabContext value={tab}>
        <Stack direction="row">
          <TabList onChange={handleTabChange}>
            <Tab label="Overview" value="1" />
            <Tab label="Lessons" value="2" />
            {data &&
              data.questionCategoryEnumId !== 1 &&
              questionCategoryTranslationsId && (
                <Tab label="Prompts" value="3" />
              )}
          </TabList>
        </Stack>
        <TabPanel value="1">
          {data && (
            <ContentDetails
              content={data}
              callback={() => {
                loadData();
              }}
            />
          )}
        </TabPanel>
        <TabPanel
          value="2"
          style={{
            height: "calc(100vh - 280px)",
          }}
        >
          {data && <Lessons data={data} reloadCallback={loadData} />}
        </TabPanel>
        {data &&
          data.questionCategoryEnumId !== 1 &&
          questionCategoryTranslationsId && (
            <TabPanel value="3">
              <ContentPrompts
                prompts={data.prompts}
                disabled={!data?.canEdit}
                questionCategoryId={parseInt(questionCategoryTranslationsId)}
              />
            </TabPanel>
          )}
      </TabContext>
    </Stack>
  );
};

export default ContentEdit;
