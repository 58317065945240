import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,

  rotate = 0,
  strokeWidth = 4,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 13C32 11.9391 32.4214 10.9217 33.1716 10.1716L37.1716 6.17157C38.7337 4.60947 41.2663 4.60948 42.8284 6.17157L46.8284 10.1716C47.5786 10.9217 48 11.9391 48 13V15.3431L44 19.3431L48 23.3431V25.3431L44 29.3431L48 33.3431V41.2142C53.9269 44.1596 58 50.2757 58 57.3431C58 67.2843 49.9411 75.3431 40 75.3431C30.0589 75.3431 22 67.2843 22 57.3431C22 50.2757 26.0731 44.1596 32 41.2142V13ZM40 61.3431C37.7909 61.3431 36 63.134 36 65.3431C36 67.5523 37.7909 69.3431 40 69.3431C42.2091 69.3431 44 67.5523 44 65.3431C44 63.134 42.2091 61.3431 40 61.3431Z"
        fill="#F2C94C"
      />
    </svg>
  );
}
