import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import moment from "moment";

import AdaptiveCard from "../components/AdaptiveCard";

interface AnnouncementMessageProps {
  message: string;
}

export default function AnnouncementMessage({
  message,
}: AnnouncementMessageProps) {
  try {
    const adaptiveCard = JSON.parse(message);

    return <AdaptiveCard card={adaptiveCard} />;
  } catch (e) {
    // @ts-ignore
    return <ReactMarkdown rehypePlugins={[rehypeRaw]}>{message}</ReactMarkdown>;
  }
}
