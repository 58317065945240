import { useEffect } from "react";

import { IViewAnnouncementModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  Chip,
  Grid,
} from "@mui/material";

import { track } from "@amplitude/analytics-browser";
import Actions from "../components/Modal/Actions";

import moment from "moment";
import AnnouncementMessage from "../components/AnnouncementMessage";

const SendAnnouncement: React.FunctionComponent<
  IViewAnnouncementModalProps
> = ({ id, announcement }) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    track("OpenedViewAnnouncement");
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <form>
        <DialogTitle>Announcement</DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <Stack spacing={2}>
            <div>
              <Grid container spacing={1} alignItems="baseline">
                <Grid item>
                  <Typography variant="h5">Sender:</Typography>
                </Grid>
                <Grid item>
                  <Typography>{announcement.displayName}</Typography>
                </Grid>
              </Grid>
            </div>

            <div>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Typography variant="h5">Teams:</Typography>
                </Grid>
                {announcement.channels.map((x) => (
                  <Grid key={x.id} item>
                    <Chip label={x.teamName} />
                  </Grid>
                ))}
              </Grid>
            </div>

            <Stack spacing={1}>
              <Typography variant="h5">Text:</Typography>
              <AnnouncementMessage message={announcement.notificationText} />
            </Stack>

            <div>
              <Grid container spacing={1} alignItems="baseline">
                <Grid item>
                  <Typography variant="h5">Time sent:</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {moment.utc(announcement.timeSent).local().format("L LT")}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Stack>
          <Actions
            neutral={{
              label: "Close",
              callback: handleClose,
            }}
          />
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default SendAnnouncement;
