// import { useEffect, useState } from "react";

// import { authentication } from "@microsoft/teams-js";
// import { IAnswerFeedback, IAnswerFeedbackResponse } from "../types/answer";

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import BookOpen from "./Icons/BookOpen";
import { IUserCourse } from "../types/course";
import moment from "moment";
import { CourseTrainingType } from "../constants/courseTrainingType";
import { CourseStatus } from "../constants/courseStatus";

interface CourseCardProps {
  item: IUserCourse;
}

const CourseCard: React.FC<CourseCardProps> = ({ item }) => {
  return (
    <Card
      sx={{
        border:
          item.status === CourseStatus.Visible
            ? "1px solid #1C1917"
            : undefined,
        borderRadius: 8,
        opacity: item.status === CourseStatus.Visible ? 1 : 0.6,
        flex: 1,
      }}
      elevation={0}
    >
      <Tooltip
        title={item.courseName}
        disableHoverListener={item.courseName.length < 20}
      >
        <CardActionArea
          sx={{
            paddingX: 4,
            paddingY: 2,
            color: "#1C1917",
            height: "100%",
            "&:hover": {
              backgroundColor: "#1C1917",
              color: "white",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <CardMedia>
            <BookOpen width={100} height={100} color="#F47923" />
          </CardMedia>
          <CardContent
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              paddingLeft: 0,
              paddingRight: 0,
              maxWidth: "100%",
            }}
          >
            <Stack>
              <Typography
                color="inherit"
                style={{
                  wordWrap: "break-word",
                  fontSize: "1.2rem",
                }}
              >
                {item.courseName}
              </Typography>
              <Typography variant="body2" color="inherit">
                in team <b>{item.channelName}</b>
              </Typography>
              {item.endDate &&
                item.courseTrainingType !== CourseTrainingType.Regular && (
                  <Typography
                    variant="body2"
                    style={{
                      color: "#F47923",
                    }}
                  >
                    Deadline:
                    {moment(item.endDate).format("L")}
                  </Typography>
                )}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Tooltip>
    </Card>
  );
};

export default CourseCard;
