import { ITeamTypeProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById, openModal } from "../features/modals";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Button,
  Typography,
} from "@mui/material";

import { track } from "@amplitude/analytics-browser";
import { useEffect } from "react";

import Actions from "../components/Modal/Actions";
import MODALS from "../constants/modals";

import teamsLogo from "../assets/img/teams-logo.svg";
import azureLogo from "../assets/img/azure-logo.svg";

import { useMsal } from "@azure/msal-react";
import { groupsRequest } from "../config/auth";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const TeamTypeModal: React.FunctionComponent<ITeamTypeProps> = ({
  id,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const { instance, accounts, inProgress } = useMsal();

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    track("OpenedContentCreate");
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>Create team</DialogTitle>
      <DialogContent style={{ overflow: "visible" }}>
        <Stack spacing={2} alignItems="center">
          <Stack spacing={2} sx={{ maxWidth: 210 }}>
            <Button
              target="_blank"
              href={`https://teams.microsoft.com/l/app/${process.env.REACT_APP_BOT_ID}`}
              variant="contained"
              onClick={() => handleClose()}
            >
              <Stack>
                <img src={teamsLogo} style={{ width: 200 }} />
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                >
                  Get Full Access with MS Teams
                  <Typography variant="required">*</Typography>
                </Typography>
              </Stack>
            </Button>
            <Button
              startIcon={<img src={azureLogo} style={{ width: 24 }} />}
              variant="contained"
              onClick={() => {
                instance
                  .acquireTokenSilent({
                    ...groupsRequest,
                    account: instance.getActiveAccount() || undefined,
                  })
                  .then((res) => {
                    handleClose();
                    dispatch(
                      openModal({
                        type: MODALS.CreateTeam,
                        callback: callback,
                      })
                    );
                  })
                  .catch((err) => {
                    console.log("err:", JSON.stringify(err));
                    if (err instanceof InteractionRequiredAuthError) {
                      instance.acquireTokenRedirect(groupsRequest);
                    }
                  });
              }}
            >
              <Typography>
                Choose Azure AD for Web Access
                <Typography variant="required">**</Typography>
              </Typography>
            </Button>
          </Stack>
          <Stack spacing={2} sx={{ maxWidth: 400 }}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <Typography variant="required">*</Typography> Unlock all Ervy
              features and enjoy the handy virtual assistant within MS Teams.
              (Recommended)
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              <Typography variant="required">**</Typography> Perfect for those
              not on MS Teams. Access Ervy at https://web.ervy.app/ with core
              features.
            </Typography>
          </Stack>
        </Stack>
        <Actions
          neutral={{
            label: "Cancel",
            callback: handleClose,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TeamTypeModal;
