import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/lab/themeAugmentation";

import { createTheme } from "@mui/material/styles";

import { grey } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    required: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    required?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    required: true;
  }
}

const baseTheme = createTheme({
  palette: {
    primary: {
      main: "#F47923",
    },
    secondary: {
      main: "#F47923",
    },
    text: {
      primary: "#1C1917",
    },
  },
  typography: {
    fontFamily: "Roboto Flex",
    required: {
      color: "#F47923",
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: "none",
          backgroundColor: "#FAEBE0",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 54,
          fontFamily: "DM Sans",
          fontWeight: 700,
        },
        h2: {
          fontSize: 48,
          fontFamily: "DM Sans",
          fontWeight: 700,
        },
        h3: {
          fontSize: 32,
          fontFamily: "DM Sans",
          fontWeight: 700,
        },
        h5: {
          fontSize: 24,
          fontFamily: "DM Sans",
          fontWeight: 400,
        },
        h6: {
          fontSize: 16,
          fontFamily: "Roboto Flex",
          weight: 400,
        },
        subtitle1: {
          fontSize: 24,
          color: "#78716C",
          fontFamily: "DM Sans",
        },
        subtitle2: {
          fontSize: 18,
          color: "#78716C",
          fontFamily: "DM Sans",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: 24,
          fontFamily: "DM Sans",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#1C1917",
          color: "#FCFCFC",
          fontFamily: "Roboto Flex",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "1px solid #1C1917",
          borderRadius: 25,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 32,
          fontFamily: "DM Sans",
          fontWeight: 700,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 2,
          borderRadius: 50,
          fontFamily: "DM Sans",
          fontWeight: 700,
          textTransform: "none",
          fontSize: 18,
          paddingLeft: 18,
          paddingRight: 18,
          paddingTop: 10,
          paddingBottom: 10,
        },
        containedPrimary: {
          color: "#fff",
          backgroundColor: "#1C1917",
          "&:hover": {
            backgroundColor: grey[800],
          },
        },
        outlinedPrimary: {
          color: "#1C1917",
        },
        containedSecondary: {
          color: "#fff",
          fontWeight: 400,
        },
        textSecondary: {
          color: "#1C1917",
          fontWeight: 400,
        },
        outlinedInfo: {
          color: "rgba(0, 0, 0, 0.26)",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          "&:hover": {
            border: "1px solid rgba(0, 0, 0, 0.26)",
          },
        },
        containedInfo: {
          color: "rgba(0, 0, 0, 0.26)",
          backgroundColor: "rgba(0, 0, 0, 0.12)",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.26)",
            boxShadow: "none",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: "#E7E5E4",
          color: "#fff",
        },
        root: {
          ":disabled": {
            opacity: 0.25,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingRight: 8,
          paddingLeft: 8,
          "&.Mui-selected, &:hover, &.Mui-selected:hover": {
            backgroundColor: "#F7D2B7",
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 34,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: "Roboto Flex",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          "&.paperHeader .MuiDataGrid-columnHeaders": {
            backgroundColor: "#fff",
            border: "1px solid #1C1917",
            borderBottom: "none",
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
          },
          "&.paperHeader .MuiDataGrid-virtualScroller": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        },
        columnHeaders: {
          border: "none",
        },
        columnHeaderTitle: {
          fontFamily: "DM Sans",
          color: "#78716C",
          fontSize: 24,
        },
        virtualScroller: {
          backgroundColor: "#fff",
          border: "1px solid #1C1917",
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        },
        // Hotfix for border on virtual scroller when there is data
        virtualScrollerContent: {
          marginLeft: -2,
        },
        // Hotfix for border on virtual scroller when there is no data
        overlayWrapperInner: {
          marginLeft: -2,
        },
        footerContainer: {
          backgroundColor: "#fff",
          border: "1px solid #1C1917 !important",
          borderTop: "none !important",
          borderBottomLeftRadius: 25,
          borderBottomRightRadius: 25,
          overflow: "hidden",
        },
        main: {
          "&:last-of-type .MuiDataGrid-virtualScroller": {
            borderBottomLeftRadius: 25,
            borderBottomRightRadius: 25,
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.4)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "28px !important",
          overflow: "hidden",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          backgroundColor: "#1C1917",
          color: "#fff",
          paddingTop: 18,
          paddingBottom: 18,
        },
        iconFilled: {
          color: "#fff",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          backgroundColor: "#FAEBE0",
          borderRadius: 28,
          overflow: "hidden",
        },
        indicator: {
          display: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "DM Sans",
          fontSize: 18,
          textTransform: "none",
          borderRadius: 28,
          "&.Mui-selected": {
            backgroundColor: "#1C1917",
            color: "#fff",
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});

export default baseTheme;
