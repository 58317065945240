import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,

  rotate = 0,
  strokeWidth = 4,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7549 57C33.3124 57.9211 33.0718 58.9434 33.0718 60C33.0718 62.4752 34.3923 64.7624 36.5359 66C38.6795 67.2376 41.3205 67.2376 43.4641 66C45.6077 64.7624 46.9282 62.4752 46.9282 60C46.9282 58.9434 46.6876 57.9211 46.245 57H33.7549Z"
        fill="#EB5757"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6274 17C35.384 17 31.3143 18.6857 28.3137 21.6863L27.6863 22.3137C24.6857 25.3143 23 29.384 23 33.6274V36.5656C23 41.9665 20.8545 47.1461 17.0355 50.9651C16.3725 51.6281 16 52.5273 16 53.4649C16 55.4173 17.5828 57.0001 19.5352 57.0001L60.4648 57.0001C62.4172 57.0001 64 55.4173 64 53.4649C64 52.5273 63.6275 51.6281 62.9645 50.9651C59.1455 47.1461 57 41.9665 57 36.5656V33.6274C57 29.384 55.3143 25.3143 52.3137 22.3137L51.6863 21.6863C48.6857 18.6857 44.616 17 40.3726 17H39.6274Z"
        fill="#F2C94C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 17.0829V14C42 12.8954 41.1046 12 40 12C38.8954 12 38 12.8954 38 14V17.0829C38.5383 17.0279 39.0814 17 39.6274 17H40.3726C40.9186 17 41.4617 17.0279 42 17.0829Z"
        fill="#F2994A"
      />
    </svg>
  );
}
